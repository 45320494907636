
<template>
  <van-sku
    v-model="show"
    :sku="sku"
    :goods="goods"
    :goods-id="info.id"
    :hide-stock="sku.hide_stock"
    show-add-cart-btn
    @buy-clicked="onBuyClicked"
    @add-cart="onAddCartClicked"
  >
    <!-- 自定义 sku-header-price -->
    <template #sku-header-price="props">
      <div class="van-sku__goods-price">
        <span class="van-sku__price-num">{{ props.price }}</span>
        <span style="color: #999999; font-size: 12px">积分</span>
      </div>
    </template>
    <!-- 自定义 sku actions -->
    <template #sku-actions="props">

      <div class="van-sku-actions">
        <van-button
          square
          size="large"
          type="warning"
          @click="props.skuEventBus.$emit('sku:addCart')"
        >
          加入购物车
        </van-button>
        <!-- 直接触发 sku 内部事件，通过内部事件执行 onBuyClicked 回调 -->
        <van-button
          v-if="buyShow"
          square
          size="large"
          type="danger"
          @click="props.skuEventBus.$emit('sku:buy')"
        >
          立即兑换
        </van-button>
      </div>
    </template>
  </van-sku>
</template>
<script>
import { Toast } from "vant";
// s商品规格组件
export default {
  props: {
    skuShow: {
      type: Boolean,
      default: false,
    },
    detailMap: {
      type: Object,
      default: () => {
        return {};
      },
    },
    buyShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      goods: {
        picture:
          "https://jf-asset1.10086.cn/pic/ware/202011/5fbb6319928df22e3f194a23.jpg",
      },
      goodsId: "",
      sku: {},
      info: "",
      specificationList: "",
      productList: "",
    };
  },
  watch: {
    skuShow() {
      this.setSku();
    },
    show(val) {
      this.$emit("update:skuShow", val);
    },
  },
  created() {
    // this.setSku();
  },
  methods: {
    setSku() {
      this.show = this.skuShow;
      if (!this.skuShow) {
        return;
      }

      let info = this.detailMap.info;
      this.specificationList = this.detailMap.specificationList;
      this.productList = this.detailMap.productList;

      let retailPrice = this.detailMap.info.retailPrice;
      this.info = info;
      this.goods = {
        title: info.name,
        picture: info.picUrl,
      };

      const tree = this.setSkuTree(this.specificationList);
      const list = this.setSkuList(this.productList, this.specificationList);
      const skuInfo = {
        price: parseInt(retailPrice), // 未选择规格时的价格
        stock_num: 999, // TODO 总库存
        collection_id: "", // 无规格商品skuId取collection_id，否则取所选sku组合对应的id
        none_sku: false, // 是否无规格商品
        hide_stock: false, // 是否隐藏剩余库存
      };
      this.sku = {
        tree,
        list,
        ...skuInfo,
      };
      console.log("this.sku", this.sku);
    },
    setSkuList(productList, specificationList) {
      var sku_list = [];
      productList.forEach((v) => {
        let sku_list_obj = {};
        v.specifications.forEach((specificationName, index) => {
          sku_list_obj["s" + (index + 1)] = this.findSpecValueIdByName(
            specificationName,
            specificationList
          );
        });
        sku_list_obj.price = v.price * 100;
        // sku_list_obj.price = v.price;
        sku_list_obj.stock_num = v.number;
        sku_list.push(sku_list_obj);
      });
      return sku_list;
    },
    findSpecValueIdByName(name, specificationList) {
      let id = 0;
      for (let item of specificationList) {
        for (let vv of item.valueList) {
          if (vv.value === name) {
            id = vv.id;
            break;
          }
        }
        if (id !== 0) {
          break;
        }
      }

      return id;
    },
    setSkuTree(arr) {
      let specifications = [];
      arr.forEach((v, k) => {
        let values = [];
        v.valueList.forEach((vv) => {
          values.push({
            id: vv.id,
            name: vv.value,
            imUrl: vv.picUrl,
          });
        });
        specifications.push({
          k: v.name,
          v: values,
          k_s: "s" + (k + 1),
        });
      });
      return specifications;
    },
    onBuyClicked(item) {
      //立即购买

      let params = this.getCardMap(item);
      console.log("item", item);
      this.$emit("onBuyClicked", {
        ...params,
        retailPrice: item.selectedSkuComb.price / 100,
      });
      // this.$emit("onBuyClicked", {
      //   ...obj,
      //   retailPrice: item.selectedSkuComb.price,
      // });
    },

    onAddCartClicked(item) {
      //加入购物车
      let params = this.getCardMap(item);

      console.log("kkk", params);
      this.$emit("onAddCartClicked", params);
    },
    getCardMap(item) {
      let params = {
        goodsId: item.goodsId,
        number: item.selectedNum,
        productId: 0,
      };
      if (item.selectedSkuComb.hasOwnProperty("s3")) {
        Toast("目前仅支持两规格");
        return;
      } else if (item.selectedSkuComb.hasOwnProperty("s2")) {
        params.productId = this.getProductId(
          item.selectedSkuComb.s1,
          item.selectedSkuComb.s2
        );
      } else {
        params.productId = this.getProductIdByOne(item.selectedSkuComb.s1);
      }
      return params;
    },
    getProductIdByOne(s1) {
      var productId = "";
      var s1_name = "";
      for (let specification of this.specificationList) {
        for (let specValue of specification.valueList) {
          if (specValue.id === s1) {
            s1_name = specValue.value;
            break;
          }
        }
        if (s1_name !== "") {
          break;
        }
      }
      for (let v of this.productList) {
        if (v.specifications.includes(s1_name)) {
          productId = v.id;
          break;
        }
      }

      return productId;
    },
    getProductId(s1, s2) {
      var productId = "";
      var s1_name = "";
      var s2_name = "";
      for (let specification of this.specificationList) {
        for (let specValue of specification.valueList) {
          if (specValue.id === s1) {
            s1_name = specValue.value;
          } else if (specValue.id === s2) {
            s2_name = specValue.value;
          }
        }
      }
      for (let v of this.productList) {
        if (
          v.specifications.includes(s1_name) &&
          v.specifications.includes(s2_name)
        ) {
          productId = v.id;
          break;
        }
      }

      return productId;
    },
  },
};
</script>

<style lang='scss' scoped>
.my-swipe {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
