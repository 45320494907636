<template>
  <div class="mall">

    <div
      class="mall-head mall-pub"
      @click="headClick"
    >
      <div class="mall-head-tit1">
        <van-icon
          class="mall-icon"
          name="award-o"
        /><span
          style="cursor: pointer;"
          @click.stop='mallLoginClick'
        ><span v-html="getModel()"></span></span>
      </div>
      <div class="mall-head-tit2">
        可用积分：<span>{{isPoints}}</span>
      </div>
      <div class="mall-head-tit3">
        <van-icon
          name="replay"
          @click.stop='refreshClick'
        />
      </div>
    </div>
    <div class="mall-seach">
      <van-search
        v-model="seachValue"
        shape="round"
        background='transparent'
        placeholder="请输入搜索关键词"
        @search="goSearch"
      />
    </div>
    <div class="mall-tag mall-pub">

      <div
        class="mall-tag-list"
        v-for="(item,index) in tagList"
        :key='index'
      >
        <label
          class="mall-tag-item"
          :class="{'tagActive':tagIndex === index}"
          @click='tagClick(item,index)'
        >{{item.name}}
          <span
            class="text-pu"
            :class="{'textACtive1':integralSwitch==='asc'}"
            v-if="item.type ==='integral'"
          ></span>
          <span
            class="text-down"
            :class="{'textACtive2':integralSwitch==='desc'}"
            v-if="item.type ==='integral'"
          ></span>
        </label>
      </div>

    </div>
    <div class="section-mall">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :offset='offset'
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="van-clearfix">
            <div
              class="mall-list "
              v-for='(item,index) in recommand'
              :key='index'
              @click="mallListClick(item)"
            >
              <div class="mall-item">
                <span
                  class="mall-span"
                  v-if="userType > 0"
                >{{item.discountName}}</span>
                <div class="mall-img">
                  <van-image
                    round
                    width="78px"
                    height="78px"
                    fit="fill"
                    :src="item.picUrl"
                  />
                </div>
                <div class="mall-tit">
                  {{item.name}}
                </div>
                <div class="mall-prict">
                  <div class="mall-prict-l"><span>{{item.retailPrice}}</span>积分</div>
                  <div class="mall-prict-r">
                    <div
                      class="mall-prict-n"
                      v-if="item.num > 0"
                    >{{item.num}}</div>
                    <van-icon
                      name="shopping-cart-o"
                      size='20'
                      :color="item.num > 0 ? variables.textColor :''"
                      @click.stop="shopClick(item,index)"
                    />
                  </div>
                </div>
                <div class="mall-foot">
                  <div class="mall-foot-btn">
                    <van-button
                      type="primary"
                      :color="variables.textColor"
                      round
                      size="small"
                      @click.stop="shopClick(item,index)"
                    >加入购物车</van-button>
                  </div>
                  <!-- <div class="mall-foot-t">{{item.supplier}}人兑换</div> -->
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>

    </div>

    <van-popup
      v-model="screenShow"
      position="right"
      :style="{ height: '100%',width:'80%' }"
      @close="onClose"
    >
      <div class="sereen-main">
        <div class="sereen-cen">
          <div class="sereen-tit"><span>积分值</span></div>
          <div class="serren-m">
            <div
              v-for='(item,index) in screenList'
              :key='index'
              :class="{'isActive':screenIndex===index}"
              class="view-t"
              @click="onScreenClick(item,index)"
            >{{item.lable}}</div>

          </div>
        </div>
        <div class="sereen-cen">
          <div class="sereen-tit"><span>分类</span></div>
          <div class="serren-m">

            <!-- <div
              v-for='(item,index) in categoryList'
              :key='index'
              :class="{'isActive':checkCategory.includes(item.value)}"
              class="view-t"
              @click="oncreenClick(item)"
            >{{item.lable}}</div> -->
            <div
              v-for='(item,index) in categoryList'
              :key='index'
              :class="{'isActive':categoryIndex===index}"
              class="view-t"
              @click="onCategoryClick(item,index)"
            >{{item.lable}}</div>
          </div>
        </div>
        <div class="sereen-cen">
          <div class="sereen-tit"><span>地区</span></div>
          <div class="serren-m">
            <!-- <div
              v-for='(item,index) in provinceList'
              :key='index'
              :class="{'isActive':checkCategory.includes(item.value)}"
              class="view-t"
              @click="screenClick(item)"
            >{{item.lable}}</div> -->
            <div
              v-for='(item,index) in provinceList'
              :key='index'
              :class="{'isActive':provinceIndex===index}"
              class="view-t"
              @click="onProvinceClick(item,index)"
            >{{item.lable}}</div>
          </div>
        </div>

      </div>
      <div class="sereen-btn">
        <van-button
          type="default"
          size="small"
          :color="variables.textColor"
          @click="onResetClck"
        >重置</van-button>
        <van-button
          type="default"
          color="#FAC4E4"
          size="small"
          @click="onSaveClick"
        >搜索到{{seachNum}}款商品 点击查看</van-button>
      </div>
    </van-popup>
    <!-- /登录 -->
    <overlay-view
      :overlayShow.sync='overlayShow'
      :isCurLOgin='isCurLOgin'
      @login='login'
    ></overlay-view>
    <!-- 商品规格 -->
    <!-- 商品规格 -->
    <goods-sku
      :skuShow.sync='skuShow'
      :detailMap='detailMap'
      @onBuyClicked='onBuyClicked'
      @onAddCartClicked='onAddCartClicked'
    ></goods-sku>
    <!-- 选择地址 -->
    <address-popup
      :saveAddressShow.sync='saveAddressShow'
      :addressList='addressList'
      :checkGoodls='checkGoodls'
      :smsSubmitType='smsSubmitType'
      @onSaveDetail='onSaveDetail'
      @onSave='onSave'
      @onDelete='onDelete'
      @onAddressSubmit='onAddressSubmit'
    ></address-popup>
    <!-- //立即兑换 -->
    <points-pop
      :integralPopup.sync='integralPopup'
      :retailPrice='retailPrice'
      :smsSubmitType='smsSubmitType'
      :checkGoodls='checkGoodls'
      @onExSubmit='onExSubmit'
    ></points-pop>
    <!-- //登录时加载状态 -->
    <van-overlay
      :show="isCurLOgin"
      class-name='loginOverlay'
    >
      <div class="wrapper">
        <div
          class=""
          style="text-align:center"
        >
          <!-- <van-loading
            size="30px"
            color="#fff"
          >登录中...</van-loading> -->
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import OverlayView from "../../components/overlay-view";
import PointsPop from "@/components/points-pop";
import AddressPopup from "@/components/address-popup";
import GoodsSku from "@/components/goods-sku";
import API from "../../api/http";
import { Toast } from "vant";
import { getRegionIu } from "@/utils/common.js";
import variables from "@/styles/variables.scss";
import AreaList from "@/utils/area";
import cast from "@/utils/cast";
export default {
  components: { OverlayView, PointsPop, GoodsSku, AddressPopup },
  name: "mall",
  data() {
    return {
      offset: 60,
      overlayShow: false,
      seachValue: "",
      integralSwitch: "",
      provinceList: [],
      tagList: [
        {
          id: 0,
          name: "全部",
          type: "rem",
        },
        {
          id: 1,
          name: "精选",
          type: "rem",
        },
        {
          id: 2,
          name: "我能兑换",
          type: "new",
        },
        {
          id: 3,
          name: "积分值",
          type: "integral",
        },
        {
          id: 4,
          name: "筛选",
          type: "screen",
        },
      ],
      tagIndex: 0,
      listQuery: {
        resource: 1,
        page: 1,
        // isHot: true,
        // isBuy: false,
        // sort: "retail_price",
        // order: "asc", //desc:将序  asc:升序
      },
      recommand: [],
      screenShow: false,
      screenIndex: "",
      screenList: [
        {
          lable: "1-1500",
          value: "1",
          minRetailPrice: 1,
          maxRetailPrice: 1500,
        },
        {
          lable: "1500-5000",
          value: "2",
          minRetailPrice: 1500,
          maxRetailPrice: 5000,
        },
        {
          lable: "5千-1万",
          value: "3",
          minRetailPrice: 5000,
          maxRetailPrice: 10000,
        },
        {
          lable: "1万以上",
          value: "4",
          minRetailPrice: 10000,
          maxRetailPrice: 99999,
        },
      ],
      categoryIndex: "",
      categoryList: [],
      provinceIndex: "",
      loading: false,
      finished: false,
      refreshing: false,
      checkCategory: [],
      seachNum: 0,
      skuShow: false, //规格显示
      integralPopup: false, //立即购买显示
      retailPrice: 0, //当前商品积分值
      detailMap: {}, //当前产品信息
      checkedAddress: "", //当前填的地址信息
      saveAddressShow: false,
      addressList: [],
      checkGoodls: {},
      chosenAddressId: "",
      smsSubmitType: 2,
      isCurLOgin: false,
    };
  },
  computed: {
    variables() {
      return variables;
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    userType() {
      return this.$store.getters["user/userType"];
    },
    phone() {
      return this.$store.state.user.phone;
    },
    isPhone() {
      return this.$store.getters["user/isPhone"];
    },
    isPoints() {
      return this.$store.getters["user/isPoints"];
    },
    userId() {
      return this.$store.getters["user/userId"];
    },
  },

  created() {
    console.log("created");
    // this.getInit();
    this.getProvince();
  },
  activated() {
    console.log("activated");

    if (this.$route.params.id) {
      this.tagClick({ id: 1, name: "精选", type: "rem" }, 1);
    }
    if (this.$route.params.name) {
      this.seachValue = this.$route.params.name;
      this.listQuery.keyword = this.seachValue;
    }
    if (this.isPhone) {
      this.getAmount();
    }
  },

  methods: {
    getAmount() {
      API.getUserAmount().then((res) => {
        if (res.errno === 0) {
          this.$store.commit("user/SET_POINTS", res.data);
        }
      });
    },
    getProvince() {
      //省市区
      let arr = Object.entries(AreaList.province_list);
      for (let item of arr) {
        this.provinceList.push({
          value: item[0],
          lable: item[1],
        });
      }
    },
    // 商品分类
    getCatalog() {
      if (this.categoryList.length === 0) {
        API.getCatalog().then((res) => {
          let list = res.data.categoryList;
          this.categoryList = list.map((item) => {
            return {
              lable: item.name,
              value: item.id,
            };
          });
        });
      }
    },
    login(data) {
      //登录

      if (this.userType !== "") {
        //存在登录记录，再次登录
        //1、如果是渠道用户，调用login_by_phone接口
        //2、如果普通用户，调用login接口
        if (this.userType === cast.USER_LEVEL0) {
          //普通用户
          this.getLogin1(data);
        } else {
          this.getLogin2({ ...data, mobileType: 1 });
        }
      } else {
        //没有登录记录,调用login接口
        this.getLogin1(data);
      }
    },
    getLogin1(data) {
      //没有登录时调用
      // type 1 正常用户 2 渠道用户

      this.isCurLOgin = true;
      this.$store
        .dispatch("user/login", {
          ...data,
          username: "",
          password: "",
          type: 1,
          mobileType: 1,
        })
        .then((res) => {
          Toast("登录成功");
          this.overlayShow = false;
          this.isCurLOgin = false;
        })
        .catch((err) => {
          Toast(err.errmsg);
          this.isCurLOgin = false;
        });
    },
    getLogin2(data) {
      this.isCurLOgin = true;
      this.$store
        .dispatch("user/shopLogin", data)
        .then((res) => {
          Toast("登录成功");
          this.isCurLOgin = false;
          this.overlayShow = false;
        })
        .catch((err) => {
          Toast(err.errmsg);
          this.isCurLOgin = false;
        });
    },
    getInit(val) {
      API.getProductsList(this.listQuery)
        .then((res) => {
          let data = res.data;
          this.loading = false;
          // if (
          //   this.screenIndex !== "" ||
          //   this.categoryIndex !== "" ||
          //   this.provinceIndex !== ""
          // ) {
          //   this.seachNum = data.total;
          // }
          this.seachNum = data.total;
          if (data.list.length > 0) {
            let list = data.list.map((item) => {
              return {
                ...item,
                num: 0,
              };
            });
            if (data.pages === 1) {
              this.finished = true;
            }

            if (val) {
              this.refreshing = false;

              // if (data.pages === 1) {
              //   this.finished = true;
              // }

              this.recommand = list;
            } else {
              this.recommand = this.recommand.concat(list);
            }

            this.listQuery.page++;
          } else {
            this.finished = true;
            this.refreshing = false;
          }
        })
        .catch(() => {
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        });
    },
    onRefresh() {
      //下拉刷星
      // 清空列表数据

      this.finished = false;
      this.refreshing = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.listQuery.page = 1;
      this.recommand = [];
      this.getInit(1);
    },
    onLoad() {
      if (this.refreshing) {
        this.recommand = [];
        this.refreshing = false;
      }
      console.log("onLoad");
      this.loading = true;
      this.getInit();
    },
    getModel() {
      let name = "点击登录";
      if (this.phone !== "" && this.isLogin) {
        name = this.phone;
      }
      return name;
    },
    mallLoginClick() {
      // 点击登录
      // if (!this.phone) {
      this.overlayShow = true;
      // }
    },
    goSearch(val) {
      //搜索
      if (val.trim()) {
        this.listQuery.keyword = val;
      } else {
        this.$delete(this.listQuery, "keyword");
      }
      this.onRefresh();
    },
    tagClick(item, index) {
      if (index === 3) {
        // 积分值
        if (this.listQuery.order) {
          this.integralSwitch = this.listQuery.order === "asc" ? "desc" : "asc";
        } else {
          this.integralSwitch = "asc";
        }
        this.listQuery.sort = "retail_price";
        this.listQuery.order = this.integralSwitch;
        this.onRefresh();
        return;
      }
      if (index === 4) {
        // 筛选
        this.screenShow = true;
        this.getCatalog();
        return;
      }
      if (index === 1) {
        //精品推荐
        // this.llistQuery = {
        //   page: 1,
        //   isHot: true,
        //   isBuy: false,
        //   sort: "retail_price",
        //   // order: "asc", //desc:将序  asc:升序
        // };
        this.listQuery.isBuy = false;
        this.listQuery.isHot = true;
      }
      if (index === 2) {
        // 我能兑换
        // this.llistQuery = {
        //   page: 1,
        //   isHot: false,
        //   isBuy: true,
        //   sort: "retail_price",
        //   // order: "asc", //desc:将序  asc:升序
        // };
        //没有登录，需要先登录
        console.log("kk", this.isLogin);
        if (!this.isPhone) {
          this.overlayShow = true;
          return;
        }
        this.listQuery.isBuy = true;
        this.listQuery.isHot = false;
      }
      if (index === 0) {
        this.$delete(this.listQuery, "isBuy");
        this.$delete(this.listQuery, "isHot");
      }
      this.tagIndex = index;
      this.onRefresh();
    },
    screenClick(item, type, index) {
      // 筛选中分类点击事件
      if (this.checkCategory.includes(item.value)) {
        this.checkCategory = this.checkCategory.filter((m) => m !== item.value);
      } else {
        this.checkCategory.push(item.value);
      }
      this.listQuery.categoryId = this.checkCategory;

      this.onRefresh();
    },
    onScreenClick(item, index) {
      if (this.screenIndex === index) {
        this.screenIndex = "";
        this.$delete(this.listQuery, "minRetailPrice");
        this.$delete(this.listQuery, "maxRetailPrice");
      } else {
        this.screenIndex = index;
        this.listQuery.minRetailPrice = item.minRetailPrice;
        this.listQuery.maxRetailPrice = item.maxRetailPrice;
      }
      console.log("666");
      this.onRefresh();
    },
    onCategoryClick(item, index) {
      if (this.categoryIndex === index) {
        this.categoryIndex = "";
        this.$delete(this.listQuery, "categoryId");
      } else {
        this.categoryIndex = index;
        this.listQuery.categoryId = item.value;
      }
      console.log("666", item);
      this.onRefresh();
    },
    onProvinceClick(item, index) {
      if (this.provinceIndex === index) {
        this.provinceIndex = "";
        this.$delete(this.listQuery, "regionCode");
      } else {
        this.provinceIndex = index;
        this.listQuery.regionCode = item.value;
      }
      this.onRefresh();
    },
    onResetClck() {
      // 重置
      this.checkCategory = [];
      this.screenIndex = "";
      this.$delete(this.listQuery, "minRetailPrice");
      this.$delete(this.listQuery, "maxRetailPrice");
      this.$delete(this.listQuery, "categoryId");
      this.onRefresh();
    },
    onSaveClick() {
      // 确定查看
      this.screenShow = false;
    },
    headClick() {
      //回到顶部
      document.documentElement.scrollTop = 0;
    },
    refreshClick() {
      //刷新
      document.documentElement.scrollTop = 0;
      this.onRefresh();
    },
    onClose() {
      //关闭筛选弹窗
      this.screenShow = false;
    },
    getDetail(id) {
      const obj = {
        id: id,
      };
      API.getProductsDetail(obj).then((response) => {
        // this.list = response.data.info;
        this.detailMap = response.data;
        this.skuShow = true;
        console.log("sku", this.sku);
      });
    },

    shopClick(item, index) {
      // 加入购物车
      if (!this.isPhone) {
        this.overlayShow = true;
        return;
      }
      this.getDetail(item.id);

      // item.num = item.num + 1;
      // let sum = this.integral - item.retailPrice;
      // this.$store.commit("user/SET_POINTS", sum);
    },
    mallListClick(item) {
      // 查看详情
      this.$router.push({
        path: "/commodity",
        query: {
          id: item.id,
        },
      });
    },
    onBuyClicked(data) {
      //立即购买
      this.skuShow = false;

      this.retailPrice = data.retailPrice;
      this.checkGoodls = data;

      //普通用户有地址则先选择地址，然后获取验证码兑换，没有则出现填写地址兑换流程
      //b端用户，有无地址都出现填写地址兑换流程
      if (this.userType === cast.USER_LEVEL0) {
        //普通用户
        this.getAddressList(1);
      } else {
        //b端
        this.integralPopup = true;
      }
      //一种方式
      // this.getAddressList(1);
      //二种方式

      // this.integralPopup = true;
    },
    onAddCartClicked(item) {
      //加入购物车
      API.addCart(item).then((res) => {
        Toast("加入购物车成功");
      });
    },
    getAddressList(val) {
      API.getAddressList().then((res) => {
        let list = res.data.list;
        this.addressList = list.map((v) => {
          return {
            ...v,
            address: v.province + v.city + v.county,
          };
        });
        if (val) {
          if (list.length > 0) {
            this.saveAddressShow = true;
          } else {
            this.integralPopup = true;
          }
        }
      });
    },
    onSaveDetail(item) {
      //新增地址的保存

      this.getAddressList();
    },
    onSave() {
      //编辑地址的保存

      this.getAddressList();
    },
    onDelete() {
      this.getAddressList();
    },
    onAddressSubmit() {
      //地址选择弹框的 确定兑换
    },
    async onExSubmit() {
      this.integralPopup = false;
      //立即兑换
      // 立即兑换
      // await this.addAddress(data);
      // await this.orderSubmit({ chosenAddressId: this.checkAddressId });
    },
    addAddress(data) {
      //没有地址时，添加的地址调用 地址添加接口，以获取地址id
      const obj = {
        id: 0,
        city: data.city,
        county: data.county,
        province: data.province,
        name: data.name,
        tel: data.tel,
        addressDetail: data.addressDetail,
        isDefault: false,
        areaCode: data.areaCode,
      };
      return API.postAddressAdd(obj).then((res) => {
        this.checkAddressId = res.data;
      });
    },
    orderSubmit(data) {
      return API.orderFastSubmit({
        goodId: this.checkGoodls.goodsId,
        addressId: data.chosenAddressId,
        productId: this.checkGoodls.productId,
        number: this.checkGoodls.number,
        iu: getRegionIu(),
      }).then((res) => {
        Toast("兑换成功");
        this.integralPopup = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
@import "~@/styles/common.scss";
.mall {
  padding-bottom: 60px;
}

.mall-head {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: #fff;
}

.mall-icon {
  color: #db7a47;
}

.mall-seach {
  width: 100%;
  margin-top: 36px;
  background-color: #fff;
}

.mall-head-tit1 {
  flex: 1;
}

.mall-head-tit2 {
  flex: 1;
}

.mall-head-tit3 {
  width: 60px;
  text-align: center;
}

.text-pu {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-color: transparent transparent #a3a3a3 transparent;
  border-width: 4px;
}

.textACtive1 {
  border-color: transparent transparent #2d2d2d transparent;
}

.text-down {
  bottom: 4px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-color: #a3a3a3 transparent transparent transparent;
  border-width: 4px;
}

.textACtive2 {
  border-color: #2d2d2d transparent transparent transparent;
}

.section-mall {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 6px;
  overflow: hidden;
}

.mall-list {
  width: 50%;
  float: left;
  padding: 0 6px;
  box-sizing: border-box;
}

.mall-item {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  margin-bottom: 10px;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
}
.mall-item .mall-span {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
}
.mall-img {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 15px;
}

.mall-img img {
  width: 100%;
  height: 100%;
}

.mall-tit {
  height: 40px;
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mall-prict {
  display: flex;
  padding-top: 6px;
}

.mall-prict-l {
  flex: 1;
  font-size: 14px;
  color: $textColor;
  text-align: left;
}
.mall-prict-l > span {
  font-size: 16px;
  font-weight: 600;
}
.mall-prict-r {
  color: #bbbbbb;
  position: relative;
}
.mall-prict-n {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  min-width: 16px;
  padding: 0 3px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  background-color: #ee0a24;
  border: 1px solid #fff;
  border-radius: 16px;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
}
.mall-prict text {
  font-size: 16px;
}

.mall-foot {
  color: #bbbbbb;
  font-size: 12px;
  padding-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.mall-foot-btn {
  flex: 1;
}
.mall-foot-t {
  width: 60px;
  text-align: right;
}

/* 弹框 */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.block {
  width: 450px;
  padding: 40px 20px;
  background: #ffffff;
  border-radius: 6px;
}
.block .btn {
  margin-top: 20px;
  text-align: center;
}

/* //筛选弹框样式 */
.sereen-main {
  height: 100%;
  box-sizing: border-box;
  padding: 15px 15px 40px 15px;
  width: 100%;
  position: relative;
  overflow-y: scroll;
}
.sereen-cen {
  margin-bottom: 20px;
}
.serren-m {
  overflow: hidden;
}
.sereen-tit {
  font-size: 16px;
  margin-bottom: 24px;
  text-align: left;
}
.sereen-btn {
  width: 100%;
  position: fixed;
  text-align: right;
  bottom: 0px;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
}
.sereen-btn .van-button {
  margin-left: 12px;
  padding-left: 16px;
  padding-right: 16px;
}
.sereen-tit span {
  font-weight: 500;
  color: $textColor;
  position: relative;
  padding-bottom: 10px;
}
.sereen-tit span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 32px;
  height: 2px;
  background-color: $background-color;
}
.view-t {
  display: inline-block;
  font-size: 14px;
  background-color: #f0f0f0;
  color: #c2c2c2;
  width: 75px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  margin-right: 15px;
  margin-bottom: 10px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.isActive {
  background-color: $textColor;
  color: #fff;
}
/* /加入购物车动销 */
.section-img {
  top: 0;
  left: 0;
  position: fixed;
  width: 80px;
  height: 80px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
}
.show-img {
  width: 60px;
  height: 60px;
}
.show-img image {
  width: 100%;
  height: 100%;
}
</style>
